export function formateTime(dateString: string) {
  // Ensure dateString is in ISO 8601 format and is marked as UTC


  // Convert to local date and time
  const localDate = new Date(dateString);

  // Format the date using the options
  const formattedTime = localDate.toLocaleTimeString('sv-SE', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return formattedTime;
}
