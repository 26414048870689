import { ThemeConfig } from '@/types';
import { useEffect, useState } from 'react';

interface ThemeWrapperProps {
  config: ThemeConfig;
  children: React.ReactNode;
}

export function ThemeWrapper({ config, children }: ThemeWrapperProps) {
  const newConfig = (typeof config === 'string' ? JSON.parse(config) : config) as ThemeConfig;

  const [unit, setUnit] = useState('vh');

  useEffect(() => {
    if (window.innerWidth > window.innerHeight) {
      setUnit('vw');
    } else {
      setUnit('vh');
    }
  });

  if (!newConfig) return <>{children}</>;


  return (
    <>
      <style>
        {`

            ${
              newConfig?.imports
                ?.map(
                  (url: string) => `
                   ${url};
                `
                )
                ?.join('\n') ?? ''
            }
      
            ${newConfig?.fontFaces?.join('\n') ?? ''}

          .theme  {
                color: ${newConfig.theme?.foreground};
                background-color: ${newConfig.theme?.background};
            }

            .theme .text-block {
                font-family: ${newConfig.theme?.fontFamily};
                font-weight: ${newConfig.theme?.fontWeight};
                color: ${newConfig.theme?.foreground};
          
            }
          

            
            .theme svg > text, .theme svg > tspan{
                font-family: ${newConfig.theme?.fontFamily};
            }


            .theme .card {
                background-color: ${newConfig.theme?.card};
            }

            .theme  .text-block  p {
                font-size: ${parseFloat(newConfig.theme?.p?.fontSize)}${unit};
                font-weight: ${newConfig.theme?.p?.fontWeight};
                color: ${newConfig.theme?.p?.foreground} ;
                font-family: ${newConfig.theme?.p?.fontFamily};
            }

            .theme .text-block   h1 {
                font-size: ${parseFloat(newConfig.theme?.h1?.fontSize)}${unit};
                font-weight: ${newConfig.theme?.h1?.fontWeight};
                color: ${newConfig.theme?.h1?.foreground};
                font-family: ${newConfig.theme?.h1?.fontFamily};
            }

            .theme .text-block  h2 {
                font-size: ${parseFloat(newConfig.theme?.h2?.fontSize)}${unit};
                font-weight: ${newConfig.theme?.h2?.fontWeight};
                color: ${newConfig.theme?.h2?.foreground};
                font-family: ${newConfig.theme?.h2?.fontFamily};
            }

            .theme .text-block h3 {
                font-size: ${parseFloat(newConfig.theme?.h3?.fontSize)}${unit};
                font-weight: ${newConfig.theme?.h3?.fontWeight};
                color: ${newConfig.theme?.h3?.foreground};
                font-family: ${newConfig.theme?.h3?.fontFamily};
            }

            .theme .text-block   h4 {
                font-size: ${parseFloat(newConfig.theme?.h4?.fontSize)}${unit};
                font-weight: ${newConfig.theme?.h4?.fontWeight};
                color: ${newConfig.theme?.h4?.foreground};
                font-family: ${newConfig.theme?.h4?.fontFamily};
            }

            .theme .text-block  h5 {
                font-size: ${parseFloat(newConfig.theme?.h5?.fontSize)}${unit};
                font-weight: ${newConfig.theme?.h5?.fontWeight};
                color: ${newConfig.theme?.h5?.foreground};
                font-family: ${newConfig.theme?.h5?.fontFamily};
            }

            .theme .text-block  h6 {
                font-size: ${parseFloat(newConfig.theme?.h6?.fontSize)}${unit};
                font-weight: ${newConfig.theme?.h6?.fontWeight};
                color: ${newConfig.theme?.h6?.foreground};
                font-family: ${newConfig.theme?.h6?.fontFamily};
            }

             .theme .booking-block .booking-name > svg > text, .booking-name{
                font-weight: ${newConfig.theme?.bookingName?.fontWeight};
                fill: ${newConfig.theme?.bookingName?.foreground};
                font-family: ${newConfig.theme?.bookingName?.fontFamily ?? newConfig.theme?.fontFamily ?? 'inherit'};
            }

              .theme .booking-block .booking-extra > svg > text, .booking-extra {
                font-weight: ${newConfig.theme?.bookingExtra?.fontWeight};
                fill: ${newConfig.theme?.bookingExtra?.foreground};
                font-family: ${newConfig.theme?.bookingExtra?.fontFamily ?? newConfig.theme?.fontFamily ?? 'inherit'};
            }

              .theme .booking-block .booking-location > svg > text, .booking-location{
                font-weight: ${newConfig.theme?.bookingLocation?.fontWeight};
                fill: ${newConfig.theme?.bookingLocation?.foreground};
                font-family: ${newConfig.theme?.bookingLocation?.fontFamily ?? newConfig.theme?.fontFamily ?? 'inherit'};
            }

              .theme .booking-block .booking-time > svg > text, .booking-time {
                font-weight: ${newConfig.theme?.bookingTime?.fontWeight};
                fill: ${newConfig.theme?.bookingTime?.foreground ?? '#222'};
                font-family: ${newConfig.theme?.bookingTime?.fontFamily ?? newConfig.theme?.fontFamily ?? 'inherit'};
            }

            .theme .booking-row:nth-child(odd) {
                background-color: ${newConfig.theme?.bookingRow?.background};
            }
                .theme .booking-row:nth-child(even) {
                background-color: ${newConfig.theme?.bookingRow?.secondaryBackground};
          }

                  .theme .booking-row {
                font-family: ${newConfig.theme?.bookingTime?.fontFamily ?? newConfig.theme?.fontFamily ?? 'inherit'};
          }
        
        
        `}
      </style>
      <div className="theme w-screen h-screen ">{children}</div>
    </>
  );
}
