import { ArrowDown, ArrowDownLeft, ArrowDownRight, ArrowLeft, ArrowRight, ArrowUp, ArrowUpLeft, ArrowUpRight } from 'lucide-react';

type TDirection = keyof typeof directions;
type SVGProps = React.SVGAttributes<SVGElement>;

const directions = {
  up: (props: any) => <ArrowUp className="w-4 h-4" {...props} />,
  down: (props: any) => <ArrowDown className="w-4 h-4" {...props} />,
  left: (props: any) => <ArrowLeft className="w-4 h-4" {...props} />,
  right: (props: any) => <ArrowRight className="w-4 h-4" {...props} />,
  'up-right': (props: any) => <ArrowUpRight className="w-4 h-4" {...props} />,
  'up-left': (props: any) => <ArrowUpLeft className="w-4 h-4" {...props} />,
  'down-right': (props: any) => <ArrowDownRight className="w-4 h-4" {...props} />,
  'down-left': (props: any) => <ArrowDownLeft className="w-4 h-4" {...props} />,
  null: () => null,
};

const directionLabels = {
  up: 'Upp',
  down: 'Ner',
  left: 'Vänster',
  right: 'Höger',
  'up-right': 'Upp höger',
  'up-left': 'Upp vänster',
  'down-right': 'Ner höger',
  'down-left': 'Ner vänster',
  null: 'Ingen pil',
};

const Direction = ({
  direction,
  ...rest
}: { direction: keyof typeof directions } & SVGProps & {
    size?: string;
  }) => {
  const Component = directions[direction];
  return <Component {...rest} />;
};

export type { TDirection };
export { directions, Direction, directionLabels };
