interface TextBlockProps {
  text: string;
  backgroundColor?: string;
  justifyContent?: string;
  alignItems?: string;
  padding?: number;
  rotation?: number;
}

function TextBlock({ text, backgroundColor, justifyContent = 'flex-start', alignItems = 'flex-start' }: TextBlockProps) {
  return (
    <div
      className="max-w-full max-h-full  h-full w-full flex text-block overflow-hidden [&>*]:break-words"
      style={{
        backgroundColor,
        justifyContent,
        alignItems,
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}

export default TextBlock;
