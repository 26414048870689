type DisplayConnection = {
  connectionId: string;
  deviceId: string;
  displayId: number;
  venueId: number;
};

type Display = {
  displayId: number;
  venueId: number;
  displayName: string;
  currentPlaylistId: number;
  location: string;
  // deviceType: string;
  deviceId: string;
  deviceName: string;
  code: string;
};

type ThemeConfig = {
  imports?: string[];
  fontFaces?: string[];
  theme: {
    background: string;
    foreground: string;
    card: string;
    accent: string;
    fontWeight: string;
    fontFamily: string;
    p: {
      foreground: string;
      fontSize: string;
      fontWeight: string;
      fontFamily: string;
    };
    h1: {
      foreground: string;
      fontSize: string;
      fontWeight: string;
      fontFamily: string;
    };
    h2: {
      foreground: string;
      fontSize: string;
      fontWeight: string;
      fontFamily: string;
    };
    h3: {
      foreground: string;
      fontSize: string;
      fontWeight: string;
      fontFamily: string;
    };
    h4: {
      foreground: string;
      fontSize: string;
      fontWeight: string;
      fontFamily: string;
    };
    h5: {
      foreground: string;
      fontSize: string;
      fontWeight: string;
      fontFamily: string;
    };
    h6: {
      foreground: string;
      fontSize: string;
      fontWeight: string;
      fontFamily: string;
    };
    bookingName: {
      foreground: string;
      fontWeight: string;
      fontFamily: string;
    };
    bookingExtra: {
      foreground: string;
      fontWeight: string;
      fontFamily: string;
    };
    bookingLocation: {
      foreground: string;
      fontWeight: string;
      fontFamily: string;
    };
    bookingTime: {
      foreground: string;
      fontWeight: string;
      fontFamily: string;
    };
    bookingRow: {
      background: string;
      secondaryBackground: string;
    };
  };
};

type Playlist = {
  playlistId: number;
  name: string;
  playlistItems?: PlaylistItems[];
  pages?: Page[];
  screens?: Screen[];
};

type PlaylistItems = {
  playlistItemId?: number | undefined;
  playlistId: number;
  pageId: number;
  sortOrder: number;
  duration: number;
  transitionIn: TransitionType | string;
  transitionOut: TransitionType | string;
  inserted?: string;
  page?: Page;
};

type Theme = {
  themeId: number;
  name: string;
  clientId: number;
  css: string | ThemeConfig;
};

type Page = {
  pageId: number;
  name: string;
  ratio: string;
  pageItems: PageItem[];
  themeId?: number;
  theme?: Theme;
  rotation?: number;
};

type BookingDisplay = {
  bookableId: number;
  bookingItemId:number;
  startTime: Date;
  endTime: Date;
  name?: string;
  bookableName?: string;
  location?: string;
  extra?: string;
  imageUrl?: string;
};

type PageItemsSettings = {
  [key: string]: any;
};

type PageItem = {
  pageId: number;
  pageItemId?: number;
  posX: number;
  posY: number;
  width: number;
  height: number;
  zindex: number;
  type: PageItemType;
  settings: PageItemsSettings;
  screens?: Screen[];
};

enum PageItemType {
  Text = 'Text',
  Image = 'Image',
  Video = 'Video',
  Youtube = 'Youtube',
  Booking = 'Booking',
  BookingList = 'BookingList',
}

enum TransitionType {
  Linear = 'linear',
  Ease = 'ease',
  EaseIn = 'ease-in',
  EaseOut = 'ease-out',
  EaseInOut = 'ease-in-out',
}

export { PageItemType, TransitionType };
export type { DisplayConnection, Display, Playlist, PlaylistItems, Page, PageItem, Theme, ThemeConfig, BookingDisplay, PageItemsSettings };
