import { axios } from '@/lib/axios';
import { BookingDisplay } from '@/types';

export const getBookingListDisplay = ({
  bookableIds,
}: {
  bookableIds: number[];
}): Promise<{
  [key: number]: BookingDisplay[];
}> => {
  return axios.get(`/api/bookingDisplay/list`, {
    params: {
      bookables: JSON.stringify(bookableIds),
    },
  });
};
