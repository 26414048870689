import { nanoid } from "nanoid";

const storagePrefix = 'display_app_';

const storage = {
  getDeviceId: () => {
    let deviceId = JSON.parse(window.localStorage.getItem(`${storagePrefix}device_id`) as string);

    if (!deviceId) {
      deviceId = nanoid();
      window.localStorage.setItem(`${storagePrefix}device_id`, JSON.stringify(deviceId));
    }
    return deviceId;
  },
  setDeviceId: (deviceId: string) => {
    window.localStorage.setItem(`${storagePrefix}device_id`, JSON.stringify(deviceId));
  },
};

export default storage;
