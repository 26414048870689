import { Page, PageItemType } from '../types';
import { PageItem } from './page-items/PageItem';

interface PageProps {
  page: Page;
}

const rotationStyle = {
  [180]: {
    transform: 'rotate(180deg)',
  },
  [90]: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transform: 'rotate(90deg) translateY(-100%)',
    WebkitTransform: 'rotate(90deg) translateY(-100%)',
    MozTransform: 'rotate(90deg) translateY(-100%)',
    MozTransformOrigin: 'top left',
    WebkitTransformOrigin: 'top left',
    transformOrigin: 'top left',
    width: '100vh',
    height: '100vw',
  },
  [270]: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transform: 'rotate(270deg) translateX(-100%)',
    WebkitTransform: 'rotate(270deg) translateX(-100%)',
    MozTransform: 'rotate(270deg) translateX(-100%)',
    MozTransformOrigin: 'top left',
    WebkitTransformOrigin: 'top left',
    transformOrigin: 'top left',
    width: '100vh',
    height: '100vw',
  },
} as const;

export function Page({ page }: PageProps) {
  const isSingleVideo = page?.pageItems?.length === 1 && (page?.pageItems?.[0]?.type as any) === PageItemType.Video && page.rotation !== 0;

  return (
    <div className="relative w-screen h-screen">
      <div className="relative w-screen h-screen" style={{ ...(!isSingleVideo ? rotationStyle[(page?.rotation ?? 0) as keyof typeof rotationStyle] ?? {} : {}) }}>
        {page?.pageItems?.map(pageItem => (
          <div
            key={pageItem.pageItemId}
            className="card"
            style={{
              position: 'absolute',
              top: `${pageItem.posY}%`,
              left: `${pageItem.posX}%`,
              width: `${pageItem.width}%`,
              height: `${pageItem.height}%`,
              zIndex: pageItem.zindex ?? 1,
            }}
          >
            <PageItem pageItem={pageItem} rotation={isSingleVideo ? page?.rotation : 0} />
          </div>
        ))}
      </div>
    </div>
  );
}
