import YouTube, { YouTubeProps } from 'react-youtube';

function getIdFromUrl(url: string) {
  if (!url) return '';

  let startString = '';

  if (url.includes('https://www.youtube.com/watch?v=')) {
    startString = 'https://www.youtube.com/watch?v=';
    var tempString = url.replace(startString, '');

    return tempString.substring(0, tempString.includes('?') ? tempString.indexOf('?') : tempString.length);
  } else if (url.includes('https://youtu.be/')) {
    startString = 'https://youtu.be/';
    var tempString = url.replace(startString, '');
    return tempString.substring(0, tempString.includes('?') ? tempString.indexOf('?') : tempString.length);
  } else if (url.includes('https://www.youtube.com/embed/')) {
    startString = 'https://www.youtube.com/embed/';
    var tempString = url.replace(startString, '');

    return tempString.substring(0, tempString.includes('?') ? tempString.indexOf('?') : tempString.length);
  }
}

function getListIdFromUrl(url: string) {
  const pattern = /src="([^"]*)"/;

  const match = url.match(pattern);

  if (match) {
    const srcUrl = match[1];
    return srcUrl;
  } else {
    return '';
  }
}

export function YoutubePageItem({ url }: { url: string, rotation?: number}) {
  const onPlayerReady: YouTubeProps['onReady'] = event => {
    event.target.playVideo();
  };

  const onEnd: YouTubeProps['onEnd'] = event => {
    event.target.playVideo();
  };

  const id = getIdFromUrl(url);

  const opts: YouTubeProps['opts'] = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 0,
      showInfo: 0,
    },
  };

  if (!url) {
    return (
      <div className="flex w-full justify-center items-center h-full">
        <p className="font-semibold">Ingen länk angiven</p>
      </div>
    );
  }

  if (url?.includes('https://www.youtube.com/embed/videoseries')) {
    const listId = getListIdFromUrl(url);
    return (
      <div className="w-full h-full pointer-events-none">
        <iframe
          width="100%"
          height="100%"
          src={listId + '&autoplay=1&controls=0&showinfo=0&rel=0&modestbranding=1&loop=1'}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
      </div>
    );
  }

  if (!id) return <div className="flex w-full justify-center items-center h-full">Felaktig länk!</div>;

  return (
    <div className="w-full h-full pointer-events-none">
      <YouTube videoId={id} onEnd={onEnd} opts={opts} onReady={onPlayerReady} className="w-full h-full" />
    </div>
  );
}
