import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { init, BrowserTracing } from '@sentry/browser';

import './polyfill';
import { AppProvider } from './providers/app';

init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [/^http:\/\/signage-app-display.s3-website.eu-north-1.amazonaws.\.com/],
    }),
  ],
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
);
