import * as React from 'react';
import { HUB_URL } from '@/config';
import { createSignalRContext } from "react-signalr/signalr";
import storage from '@/utils/storage';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';

const SignalRContext = createSignalRContext();

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const deviceId = storage.getDeviceId();
  return (
    <SignalRContext.Provider
      logMessageContent
      onOpen={(connection: HubConnection) => (connection.state === HubConnectionState.Connected && deviceId ? connection.invoke('DisplayConnect', deviceId)?.catch((error: any) => console.error(error)) : null)}
      automaticReconnect
      timeout={60000}
      connectEnabled
      url={HUB_URL}
      dependencies={[deviceId]}
    >
      {children}
    </SignalRContext.Provider>
  );
};

export { SignalRContext };
