import { useEffect, useRef, useState } from 'react';
import { Playlist, TransitionType } from '../types';
import { Transition } from '@headlessui/react';
import { Page } from './Page';
import { ThemeWrapper } from './ThemeWrapper';

interface PlayListProps {
  playlist: Playlist;
}

function PlayList({ playlist }: PlayListProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [theme, setTheme] = useState(playlist?.playlistItems?.[currentIndex]?.page?.theme?.css);
  const intervalRef = useRef<any>(null);

  const pages = playlist?.playlistItems?.sort((a, b) => a.sortOrder - b.sortOrder)?.map(playlistItem => playlistItem.page) ?? [];
  const currentPage = pages?.[currentIndex];

  const transitionIn = playlist?.playlistItems?.[currentIndex]?.transitionIn ?? TransitionType.EaseInOut;
  const transitionOut = playlist?.playlistItems?.[currentIndex]?.transitionOut ?? TransitionType.EaseInOut;

  useEffect(() => {
    setCurrentIndex(0);
    clearInterval(intervalRef.current);
  }, [playlist?.playlistId]);

  useEffect(() => {
    const playlistItem = playlist?.playlistItems?.sort((a, b) => a.sortOrder - b.sortOrder)?.[currentIndex];
    const duration = playlistItem?.duration ?? 10;

    const interval = setInterval(() => {
      setCurrentIndex(currentIndex => {
        if (currentIndex === pages.length - 1) {
          return 0;
        }
        return currentIndex + 1;
      });
      clearInterval(intervalRef.current);
    }, duration * 1000);

    intervalRef.current = interval;

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [currentIndex]);

  // return (
  //   <div className="w-full h-screen">
  //     <Transition
  //       key={currentPage?.pageId}
  //       show={true}
  //       enter={`transition-opacity ${transitionIn} duration-[1s] delay-[1s]`}
  //       enterFrom="opacity-0"
  //       enterTo="opacity-100"
  //       leave={`transition-opacity ${transitionOut} duration-[1s] delay-[0.5s]`}
  //       leaveFrom="opacity-100"
  //       leaveTo="opacity-0"
  //     >
  //       <ThemeWrapper key={currentPage?.themeId} config={currentPage?.theme?.css as any}>
  //         {currentPage && <Page page={currentPage} />}
  //       </ThemeWrapper>
  //     </Transition>
  //   </div>
  // );

  return (
    <>
      <div className="w-full h-screen bg-white">
        {pages?.map(page => {
          return (
            <Transition
              key={page?.pageId}
              show={page?.pageId === currentPage?.pageId}
              enter={`transition-opacity ${transitionIn} duration-[1s] delay-[1s]`}
              enterFrom="opacity-0 " // Add translateZ(0) to promote to a new layer
              enterTo="opacity-100"
              leave={`transition-opacity ${transitionOut} duration-[1s] delay-[0.5s]`}
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              beforeEnter={() => {
                const timeout = setTimeout(() => {
                  setTheme(currentPage?.theme?.css);
                  clearTimeout(timeout);
                }, 1050);
              }}
            >
              <ThemeWrapper key={page?.pageId} config={theme as any}>
                {page && <Page page={page} />}
              </ThemeWrapper>
            </Transition>
          );
        })}
      </div>
    </>
  );
}

export default PlayList;
