import { useState } from 'react';
import { HubConnectionState } from '@microsoft/signalr';

import { DisplayConnection, Playlist } from '../types';
import storage from '../utils/storage';
import { SignalRContext } from '@/providers/app';

export function useDisplayConnection() {
  const deviceId = storage.getDeviceId();
  const [displayConnection, setDisplayConnection] = useState<DisplayConnection | null>(null);
  const [playlist, setPlaylist] = useState<Playlist | null>(null);

  SignalRContext.useSignalREffect(
    'onDisplayConnect',
    (activeDisplayConnection: DisplayConnection) => {
      setDisplayConnection(activeDisplayConnection);
      SignalRContext.invoke('GetActivePlaylist', activeDisplayConnection.displayId)?.catch((error: any) => console.error(error));
    },
    [displayConnection, playlist]
  );

  SignalRContext.useSignalREffect(
    'onNewDisplay',
    (activeDisplayConnection: DisplayConnection) => {
      console.info('onNewDisplay', activeDisplayConnection);
      setDisplayConnection(activeDisplayConnection);
    },
    [displayConnection, playlist]
  );

  SignalRContext.useSignalREffect(
    'onGetActivePlaylist',
    (activePlaylist: any) => {
      console.info('onGetActivePlaylist', activePlaylist);
      setPlaylist(activePlaylist);
    },
    [displayConnection, playlist]
  );

  SignalRContext.useSignalREffect(
    'onRefreshDisplay',
    () => {
      console.info('onRefreshDisplay');
      window.location.reload();
    },
    [displayConnection, playlist]
  );

  SignalRContext.useSignalREffect(
    'forceDisconnect',
    (action: string) => {
      if (action === 'reload') {
        storage.setDeviceId('');
        window.location.reload();
      }
    },
    [displayConnection, playlist]
  );

  SignalRContext.connection?.onreconnected((_: any) => {
    SignalRContext.invoke('DisplayConnect', deviceId)?.catch((error: any) => console.error(error));
  });

  return {
    hubstate: {
      connected: SignalRContext.connection?.state === HubConnectionState.Connected,
      connecting: SignalRContext.connection?.state === HubConnectionState.Connecting,
      disconnected: SignalRContext.connection?.state === HubConnectionState.Disconnected,
      disconnecting: SignalRContext.connection?.state === HubConnectionState.Disconnecting,
      reconnecting: SignalRContext.connection?.state === HubConnectionState.Reconnecting,
    },
    connection: displayConnection,
    playlist,
  };
}
